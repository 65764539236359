<template>
  <div class="floor-plan-edit">
    <div class="card">
      <fd-form class="modal-content-wrapper" @submit.prevent="submitForm">
        <!-- Content -->
        <modal-body>
          <div class="container fluid">
            <h3 class="p-3 mb-4 lineBottom">Edit Floor Plan</h3>

            <div class="row px-3 mb-2">
              <fd-input
                v-model="floorPlan.name"
                class="col-12 px-1 mb-2"
                label="Floor Plan Name"
                name="floorPlanName"
                type="text"
                :validators="[validator.required]"
              >
              </fd-input>
              <fd-select
                v-model="floorPlan.lotTypeId"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Lot Type"
                name="lotTypeId"
                :options="lotTypeOptions"
              >
              </fd-select>
              <div class="col-12 px-1 mb-2 thumbnail">
                <image-uploader
                  v-model="floorPlan.thumbnail"
                  label="Thumbnail"
                  :multiple="false"
                  required
                  watermark
                  @error="
                    (error, imageName) => {
                      $reportError(error, 'Upload thumbnail (Edit Floor Plan)');
                    }
                  "
                >
                </image-uploader>
              </div>
              <div class="col-12 px-1 mb-2">
                <image-uploader
                  v-model="floorPlan.image"
                  label="Floor Plan Images"
                  multiple
                  required
                  watermark
                  @error="
                    (error, imageName) => {
                      $reportError(error, 'Upload FP Image (Edit Floor Plan)');
                    }
                  "
                >
                </image-uploader>
              </div>
              <div class="col-12 px-1 mb-2">
                <image-uploader
                  v-model="floorPlan.unitImage"
                  label="Unit Images"
                  multiple
                  watermark
                  @error="
                    (error, imageName) => {
                      $reportError(error, 'Upload Unit Img (Edit Floor Plan)');
                    }
                  "
                >
                </image-uploader>
              </div>
            </div>

            <fd-form-section class="px-3" title="Land Details">
              <!-- Editable at unit -->
              <div class="col-12 d-flex align-items-center px-1 mb-2">
                <fd-checkbox
                  v-model="floorPlan.isEdit"
                  class="d-inline-block"
                  label="Editable for unit"
                >
                </fd-checkbox>
                <button
                  type="button"
                  class="btn ml-2"
                  v-tooltip="
                    `Check this if you want to make land details editable at unit, else it will inherit the data from floor plan.`
                  "
                >
                  <i class="fas fa-info"></i>
                </button>
              </div>
              <!-- Land Width -->
              <fd-input
                v-model="floorPlan.landWidth"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Land Width"
                name="landWidth"
                type="number"
                @input="landChanged"
              >
              </fd-input>
              <!-- Land Depth -->
              <fd-input
                v-model="floorPlan.landDepth"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Land Depth"
                name="landDepth"
                type="number"
                @input="landChanged"
              >
              </fd-input>
              <!-- Land Area -->
              <fd-input
                v-model="floorPlan.landArea"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Land Area"
                name="landArea"
                type="number"
                :validators="[validator.required]"
              >
              </fd-input>
              <!-- Area Unit Type -->
              <fd-select
                v-model="floorPlan.areaUnitType"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Area Unit Type"
                name="areaUnitType"
                :options="areaUnitTypeOptions"
                :validators="[validator.required]"
              >
              </fd-select>
              <!-- Built Up Width -->
              <fd-input
                v-model="floorPlan.builtUpWidth"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Built Up Width"
                name="builtUpWidth"
                type="number"
                @input="builtUpChanged"
              >
              </fd-input>
              <!-- Built Up Depth -->
              <fd-input
                v-model="floorPlan.builtUpDepth"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Built Up Depth"
                name="builtUpDepth"
                type="number"
                @input="builtUpChanged"
              >
              </fd-input>
              <!-- Built Up Area -->
              <fd-input
                v-model="floorPlan.builtUpArea"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Built Up Area"
                name="builtUpArea"
                type="number"
                :validators="[validator.required]"
              >
              </fd-input>
            </fd-form-section>
            <fd-form-section class="px-3" title="Pricing Details">
              <div class="row col-12 md-col-6">
                <!-- Sale Price -->
                <fd-input
                  v-model="floorPlan.salePrice"
                  class="col-12 px-1 mb-2"
                  label="Sale Price (RM)"
                  name="salePrice"
                  type="number"
                  @input="updatePrice"
                >
                </fd-input>
                <!-- Unit Price Type -->
                <fd-select
                  v-model="pricePerUnitOrigin"
                  class="col-12 px-1 mb-2"
                  label="Unit Price Calculate With"
                  name="unitPriceType"
                  :options="unitPriceCalculateOptions"
                  :optionValue="
                    (option) => {
                      return option;
                    }
                  "
                  :optionLabel="
                    (option) => {
                      return option;
                    }
                  "
                  @change="updatePrice"
                >
                </fd-select>
              </div>
              <div class="col-12 md-col-6">
                <div class="sales-price-per-unit md-mx-2">
                  <label class="label">Sales Price per Unit</label>
                  <p v-if="!floorPlan.areaUnitType">
                    Choose an area unit type first
                  </p>
                  <p v-else>
                    RM{{ floorPlan.salePricePerUnit || "0.00" }}
                    {{ floorPlan.areaUnitType }}
                  </p>
                </div>
              </div>
            </fd-form-section>

            <fd-form-section class="px-3" title="Additional Details">
              <div class="col-12 row">
                <fd-input
                  v-model="floorPlan.numberOfBedroom"
                  class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                  label="No. of Bedroom"
                  name="numberOfBedroom"
                  type="number"
                  min="0"
                  step="1"
                  pattern="\d*"
                >
                </fd-input>
                <fd-input
                  v-model="floorPlan.numberOfBathroom"
                  class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                  label="No. of Bathroom"
                  name="numberOfBathroom"
                  type="number"
                  min="0"
                  step="1"
                  pattern="\d*"
                >
                </fd-input>
              </div>
              <div class="col-12 row">
                <fd-input
                  v-model="floorPlan.extraBedroom"
                  class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                  label="Extra Bedroom"
                  name="extraBedroom"
                  type="number"
                  min="0"
                  step="1"
                  pattern="\d*"
                >
                </fd-input>
                <fd-input
                  v-model="floorPlan.extraBathroom"
                  class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                  label="Extra Bathroom"
                  name="extraBathroom"
                  type="number"
                  min="0"
                  step="1"
                  pattern="\d*"
                >
                </fd-input>
              </div>
            </fd-form-section>
          </div>
        </modal-body>

        <!-- Action Footer -->
        <modal-footer class="p-2">
          <div class="col-12 text-right">
            <button type="button" class="btn mr-1" @click="$emit('cancel')">
              Cancel
            </button>
            <button class="btn main">Update</button>
          </div>
        </modal-footer>
      </fd-form>
    </div>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";

import ManagerRoles from "@/modules/Project/classes/ManagerRoles";
import ProjectFloorPlanAPI from "@/modules/Project/api/projectFloorPlan";
import * as propertyAPI from "@/modules/Property/api/property";
import ProjectFloorPlanModel from "@/modules/Project/models/ProjectFloorPlanModel";

import { propertyCategory, areaUnitType, orientation } from "@/enums";
import { calculateArea, calculateUnitPrice } from "@/utils/property";

export default {
  components: {
    ImageUploader: () => import("@/components/GlobalComponents/ImageUploader")
  },
  mixins: [],
  props: {
    type: {
      type: String,
      required: true,
      validator: (val) => new ManagerRoles().getRoles().includes(val)
    },
    floorPlanId: {
      type: [Number, String],
      required: true
    },
    propertyTypeId: {
      type: [Number, String],
      required: true
    }
  },
  data: function () {
    return {
      floorPlanAPI: new ProjectFloorPlanAPI(this.type),

      isLoading: false,
      lotTypeOptions: [],
      propertyCategoryOptions: this.$mapJsonToArray(propertyCategory, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      areaUnitTypeOptions: this.$mapJsonToArray(areaUnitType, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      orientationOptions: this.$mapJsonToArray(orientation, (e) => {
        return {
          id: e,
          name: e
        };
      }),

      unitPriceCalculateOptions: ["Built Up", "Land"],
      pricePerUnitOrigin: "Built Up",

      floorPlan: {
        projectUnitTypeId: "",
        image: [],
        thumbnail: [],
        unitImage: [],

        landArea: 0,
        builtUpArea: 0
      },

      validator: {
        required: required
      }
    };
  },
  computed: {
    areaValue: function () {
      let val = 0;
      if (this.pricePerUnitOrigin == "Built Up") {
        val = this.floorPlan.builtUpArea;
      } else if (this.pricePerUnitOrigin == "Land") {
        val = this.floorPlan.landArea;
      }
      return val;
    }
  },
  watch: {
    "floorPlan.landArea": {
      immediate: true,
      handler() {
        if (this.pricePerUnitOrigin == "Land") {
          this.updatePrice();
        }
      }
    },
    "floorPlan.builtUpArea": {
      immediate: true,
      handler() {
        if (this.pricePerUnitOrigin == "Built Up") {
          this.updatePrice();
        }
      }
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        await Promise.all([this.getFloorPlanData(), this.getLotTypes()]);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },
    // =========================== LAND CALCULATIONS ===========================
    updatePrice() {
      this.$nextTick(() => {
        this.floorPlan = {
          ...this.floorPlan,
          salePricePerUnit: this.updateUnitPrice(this.floorPlan.salePrice)
        };
      });
    },
    landChanged() {
      this.floorPlan.landArea = this.calculateArea(
        this.floorPlan.landWidth,
        this.floorPlan.landDepth
      );
    },
    builtUpChanged() {
      this.floorPlan.builtUpArea = this.calculateArea(
        this.floorPlan.builtUpWidth,
        this.floorPlan.builtUpDepth
      );
    },
    calculateArea: calculateArea,
    calculateUnitPrice: calculateUnitPrice,
    updateUnitPrice(salePrice = 0) {
      if (salePrice == 0 || this.areaValue == 0) {
        return "0.00";
      }

      return this.calculateUnitPrice(salePrice, this.areaValue);
    },
    // ============================== API Related ==============================
    async getFloorPlanData() {
      try {
        let data = await this.floorPlanAPI.getFloorPlan(this.floorPlanId);
        this.floorPlan = ProjectFloorPlanModel.getToEditResponse(data);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        this.$reportError(error, "Get Floor Plan to edit");
        throw error;
      }
    },
    async getLotTypes() {
      try {
        let data = await propertyAPI.getLotTypes({
          queries: {
            "propertyType:id": this.propertyTypeId
          }
        });

        this.lotTypeOptions = this._.cloneDeep(data);
      } catch (error) {
        throw error;
      }
    },
    submitForm() {
      this.$emit(
        "submit",
        this.floorPlanId,
        ProjectFloorPlanModel.postPayload(this.floorPlan)
      );
    }
  }
};
</script>

<style lang="scss">
.floor-plan-edit {
  @extend %formDesign;
  width: 100%;
  .sales-price-per-unit {
    border: solid 1px #00000025;
    padding: 15px 15px;
    border-radius: 5px;
    .label {
      font-weight: bold;
    }
  }
}
</style>
